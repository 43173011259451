class Globals {

    constructor() {

        const mobileButton = document.getElementById('mobileNavButton');
        const mobileClose = document.getElementById('mobileClose');
        const searchIcon = document.getElementById('searchIcon');
        const addressSubmit = document.getElementById('addressSubmit');

        window.addEventListener('resize', () => {
            if(window.innerWidth > 768) {
                this.closeMenu();
            }
        }, true);


        //Attach event listener to close button.
        mobileClose.addEventListener("click", e => {
            this.closeMenu();
        });

        //Attach event listener to hamburger.
        mobileButton.addEventListener("click", e => {
            this.openMenu();
        });

        if(searchIcon && addressSubmit) {
            searchIcon.addEventListener("click", e => {
                addressSubmit.click();
            });
        }

        if(addressSubmit) {
            addressSubmit.addEventListener("click", e => {
                this.scrollUp();
            });
        }
}

    openMenu() {
        const body = document.querySelector('body');
        body.classList.add('active');
    }

    closeMenu() {
        const body = document.querySelector('body');
        body.classList.remove('active');
    }

    scrollUp() {
        const resultsBox = document.getElementById('results_box_output');
        resultsBox.scrollTop = 0;
    }
}

export default Globals;