/**
 * Main project app script!
 *
 * All script functionality should be in this file.
 *
 * NOTE: jQuery by default is NOT supported! Please make sure to follow ES6 paradigms and create vanilla JS
 * wherever possible. jQuery is included for the sake of plugins that we rely on such as Gravity Forms.
 * Refer to the document at https://wiki.posturedev.com for further information.
 **/

//Modules
// import { Glightbox, AOS, Swiper } from "Parent/js/modules/modules";

// All generic global site javascript should live in this file.
import Globals from './globals';
import A11yDialog from 'a11y-dialog';

// prevents platform from removing parent utility imports
import utilities from "Parent/js/utilities/utilities";

utilities;

// Attach the scripts after the website is up and running
document.addEventListener("DOMContentLoaded", () => {
	// Remove and uncomment the enqueue script in functions.php if not needed
	window.$ = jQuery();

	const globals = new Globals();
	const dialogEl = document.getElementById('formConfirmation');

	if(typeof(dialogEl) != 'undefined' && dialogEl != null) {
		let dialog = new A11yDialog(dialogEl);

		jQuery(document).on('gform_confirmation_loaded', function(event, formId){
			dialog.show();
		});
	}

	//jQuery("#input_4_6_1").prop('checked', true);
	jQuery("#input_5_6_1").prop('checked', true);
});
